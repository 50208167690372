<template>
  <div>
    <div class="row table-utilities mx-0">
      <div class="row ml-2 w-100">
        <div class="col-md-10 search-section">
          <div class="input-group">
            <div class="input-group-prepend bg-white">
              <span class="input-group-text" id="basic-addon1">
                <div class="font-12">
                  <i class="ri-search-line ri-lg ri-sub"></i>
                </div>
              </span>
            </div>
            <input
              type="text"
              class="form-control search-input"
              :placeholder="$t('monthlyplan.search')"
              @input="setPage"
              v-model="filterInput"
            />
          </div>
        </div>
        <div class="col-md-2 pl-0">
          <button
            class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
            @click="handleExport"
            :disabled="isLoading"
          >
            <div class="font-12">
              <div
                v-if="isLoading"
                class="spinner-border spinner-border-sm text-success mr-1"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
            </div>
            {{ $t("global.export") }}
          </button>
        </div>
      </div>
      <div class="row w-100 ml-2 mt-2">
        <div class="col-md-2 pl-0">
          <b-button
            class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
            id="popover-button-filter-2"
            href="#"
            tabindex="0"
          >
            <div class="font-12">
              <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
            </div>
            Filter Data
          </b-button>
        </div>
        <b-popover
          placement="right"
          target="popover-button-filter-2"
          triggers="focus"
        >
          <b-tabs content-class="mt-2">
            <b-tab title="Tanggal" active>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-today"
                  :value="filterDateTypes[0]"
                  @change="resetFilter1"
                  checked
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-today">
                  <div class="col">
                    <div class="row ssp-14">Today</div>
                    <div class="row">{{ today | formatDate2 }}</div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-yesterday"
                  :value="filterDateTypes[1]"
                  @change="resetFilter1"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-yesterday">
                  <div class="col">
                    <div class="row ssp-14">Yesterday</div>
                    <div class="row">{{ yesterday | formatDate2 }}</div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-week"
                  :value="filterDateTypes[2]"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-week">
                  <div class="col">
                    <div class="row ssp-14">This week</div>
                    <div class="row">
                      <span>{{ `${getDay(firstDayWeek)}&nbsp;-&nbsp;` }}</span>
                      <span>{{ lastDayWeek | formatDate2 }}</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-month"
                  :value="filterDateTypes[3]"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-month">
                  <div class="col">
                    <div class="row ssp-14">This month</div>
                    <div class="row">
                      <span>{{ `${getDay(firstDayMonth)}&nbsp;-&nbsp;` }}</span>
                      <span>{{ lastDayMonth | formatDate2 }}</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-custom"
                  :value="filterDateTypes[4]"
                  v-model="filterDateType"
                />
                <label class="form-check-label ssp-14" for="radio-custom">
                  Custom Range
                </label>
              </div>
              <b-row v-if="filterDateType == filterDateTypes[4]" class="mb-2">
                <b-col cols="6" class="pr-1">
                  <span>FROM</span>
                  <input
                    type="date"
                    class="form-control float-right"
                    v-model="filterSelected1"
                  />
                </b-col>
                <b-col cols="6" class="pl-1">
                  <span>TO</span>
                  <input
                    type="date"
                    class="form-control"
                    v-model="filterSelected2"
                  />
                </b-col>
              </b-row>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 ssp-14"
                :class="{ disabled: filterDateType === null }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
            <b-tab title="User">
              <div class="filter-overflow-srch">
                <div
                  class="form-checkbox"
                  v-for="user in dataUser"
                  :key="user.id"
                >
                  <input
                    class="form-checkbox-item"
                    type="checkbox"
                    name="kegiatan-label"
                    :id="user.id"
                    :value="user.id"
                    v-model="filterUser"
                  />
                  <label class="form-checkbox-label ssp-14-400" :for="user.id">
                    {{ user.name }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterUser == '' }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
            <b-tab title="Kegiatan">
              <div class="filter-overflow">
                <div
                  class="form-checkbox"
                  v-for="kegiatan in jenisKegiatanType"
                  :key="kegiatan.id"
                >
                  <input
                    :id="kegiatan.value"
                    class="form-radio-item"
                    type="radio"
                    name="kegiatan-label"
                    :value="kegiatan.value"
                    v-model="filterKegiatan"
                  />
                  <label
                    class="form-radio-label ssp-14-400"
                    :for="kegiatan.value"
                  >
                    {{ kegiatan.label }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterKegiatan === null }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
          </b-tabs>
        </b-popover>
      </div>
    </div>
    <div class="table-borderless">
      <b-table-simple hover responsive>
        <b-thead class="bv-head">
          <b-tr>
            <b-th
              ><input type="checkbox" @click="selectAll" v-model="allSelected"
            /></b-th>
            <b-th v-for="hd in $t('monthlyplan.headers')" :key="hd.id">
              {{ hd }}
            </b-th>
          </b-tr>
        </b-thead>
        <!-- <b-tbody> -->
        <b-tbody v-if="this.dataPlan.length !== 0">
          <b-tr class="ssp-16-400" v-for="plan in dataPlan" :key="plan.id">
            <b-td
              ><input
                type="checkbox"
                :value="plan.id"
                v-model="planIds"
                @click="select"
            /></b-td>
            <b-td>{{ plan.users.name }}</b-td>
            <!-- <b-td>{{ plan.regions.region }}</b-td> -->
            <b-td>{{ plan.tanggal | formatDate2 }}</b-td>
            <b-td>{{ plan.lokasi }}</b-td>
            <!-- <b-td>{{ plan.tanamans.crop }}</b-td> -->
            <b-td>{{ plan.produks.brand }}</b-td>
            <b-td>
              <span class="badge badge-blue">{{
                convertKegiatan(plan.kegiatan)
              }}</span>
            </b-td>
            <b-td>
              <div class="action-wrapper font-12">
                <i
                  class="ri-eye-fill ri-lg ri-mid action-btn"
                  @click="onDetail(plan)"
                ></i>
                <!-- <i class="ri-delete-back-2-fill ri-lg ri-mid action-btn" @click="onDelete(laporan.id)"></i> -->
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else class="text-center text-italic">
          <!-- <b-tbody class="text-center"> -->
          <b-tr>
            <b-td colspan="10">{{ $t("global.empty") }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="row align-items-center table-footer">
        <div class="col-sm-6 ssp-14-700">
          <!-- 1 - 6 of {{ this.totalData }} -->
          {{ $t("global.total_data") }} {{ this.totalData }}
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-7 align-self-center page-text">
              {{ $t("global.page_on") }}
            </div>
            <div class="col float-right">
              <div class="row">
                <select class="form-control pagination-pg" v-model="currPage">
                  <option
                    v-for="index in totalPages"
                    :key="index.id"
                    :value="index"
                  >
                    {{ index++ }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col page-arrow ssp-14-700 ml-2">
              <div class="row float-right">
                <div
                  @click="redcPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div class="font-12" :class="{ orange: currPage > 1 }">
                    <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                  </div>
                </div>
                <div
                  @click="addPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div
                    class="font-12"
                    :class="{ orange: currPage < totalPages }"
                  >
                    <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-plan" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form>
          <div class="form-group">
            <label
              for="territory/wilayah"
              class="ssp-16 text-black"
              @click="filterTerritory"
              >{{ $t("monthlyplan.label.terr") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model="formTambah.territory"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >Nama Sales Promotor
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.sp"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >Distributor
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.distributor"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("dailyreport.label.vr") }} 1
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.r1"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("monthlyplan.label.activity") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.kegiatan"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("dailyreport.label.date") }}
            </label>
            <input
              type="date"
              class="form-control"
              :class="{
                'invalid-form rounded-input': formTambah.tanggal.$error
              }"
              v-model="formTambah.tanggal"
              :disabled="isDetail"
            />
          </div>
          <div class="form-group">
            <label for="lokasi-kegiatan" class="ssp-16 text-black">{{
              $t("monthlyplan.label.loc")
            }}</label>
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': formTambah.lokasi.$error }"
              id="lokasi-kegiatan"
              cols="30"
              rows="4"
              :placeholder="$t('monthlyplan.placeholder.loc')"
              v-model="formTambah.lokasi"
              :disabled="isDisabled"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("monthlyplan.label.crop") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.crop"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="retailer-1" class="ssp-16 text-black"
              >{{ $t("monthlyplan.label.prod") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.produk"
              :disabled="true"
            />
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

import JwtService from "@/core/services/jwt.service";
import { Q_LIST_USERSEARCH } from "@/graphql/queries";
import { Q_LIST_MPLAN } from "@/graphql/cm";
import {
  RetailerType,
  JenisKegiatan,
  RoleType,
  filterDateType
} from "@/graphql/enum.type.js";
import Axios from "axios";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      dataPlan: [],
      dataUser: [],
      dataUserId: [],
      dataRegion: [],

      detailUser: JwtService.getDetail(),
      filterRole: RoleType.SP,
      filterUser: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterKegiatan: null,
      filterRegion: [],
      show_filter: false,
      customFilter: false,
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null,

      planIds: [],
      selected: [],
      allSelected: false,

      dataR1: [],
      dataSP: [],
      dataKab: [],
      dataProduk: [],
      dataTanaman: [],
      dataDistributor: [],

      roleOptions: [RoleType.SP, RoleType.TSS],
      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],
      // roleOptions1: [RoleType.RM, RoleType.AM, RoleType.TSS, RoleType.SP],
      retailerTypeOptions: [RetailerType.R1, RetailerType.R2],
      jenisKegiatanType: [
        JenisKegiatan.BF,
        JenisKegiatan.DP,
        JenisKegiatan.FFD,
        JenisKegiatan.KF,
        JenisKegiatan.ODP,
        JenisKegiatan.R1M,
        JenisKegiatan.R2M,
        JenisKegiatan.SAF,
        JenisKegiatan.SE,
        JenisKegiatan.SMF
      ],
      modalTitle: "",

      isEdit: false,
      isDisabled: false,
      isDetail: false,

      formTambah: {
        id: "",
        territory: "",
        sp: "",
        distributor: "",
        r1: "",
        kegiatan: "",
        tanggal: "",
        lokasi: "",
        crop: "",
        produk: ""
      }
    };
  },
  apollo: {
    listMonthlyPlan: {
      query: () => Q_LIST_MPLAN,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          region_id: this.getRegionID(),
          user_id: this.filterUser,
          role: RoleType.TSS,
          plan_type: this.filterKegiatan,
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataPlan = data.listMonthlyPlan.monthlys;
        this.totalPages = data.listMonthlyPlan.totalPage;
        this.totalData = data.listMonthlyPlan.total;
      },
      error(err) {
        this.catchError(err);
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: "",
          role: RoleType.TSS,
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          prov_id: this.getProvinceID(),
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      // update: data => data.listUser,
      result({ data }) {
        this.dataUser = data.listUser.users;
        this.dataUserId = data.listUser.users.map(a => {
          return a.id;
        });
      }
    }
  },
  computed: {},
  methods: {
    filterTerritory(data) {
      this.dataKab.forEach(val => {
        if (data === val.id) {
          return val.kabupaten;
        }
      });
    },
    getDay(value) {
      return moment(value).format("DD");
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    setFilterSelectedWeek() {
      this.filterSelected2 = this.lastDayWeek;
      this.customFilter = false;
      this.setPage();
    },
    setFilterSelectedMonth() {
      this.filterSelected2 = this.lastDayMonth;
      this.customFilter = false;
      this.setPage();
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.customFilter = false;
      this.filterKegiatan = null;
      this.filterUser = [];

      var elements = document.getElementsByTagName("input");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].type == "radio") {
          elements[i].checked = false;
        }
      }
    },
    resetFilter1() {
      this.filterSelected2 = "";
      this.customFilter = false;
      this.setPage();
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    selectAll() {
      this.planIds = [];
      if (!this.allSelected) {
        for (let i in this.dataPlan) {
          this.planIds.push(this.dataPlan[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },

    onAdd() {
      this.modalTitle = "Tambah Rencana Bulanan";
      this.isEdit = false;
      this.isDetail = false;
      this.isDisabled = false;
      this.resetForm();
      this.$bvModal.show("modal-plan");
    },
    onDetail(data) {
      this.modalTitle = "Detail Rencana Bulanan";
      this.isEdit = false;
      this.isDetail = true;
      this.isDisabled = true;
      this.resetForm();

      this.formTambah.territory = data.territory;
      if (data.sales_promotor !== null)
        this.formTambah.sp = data.sales_promotor.name;
      if (data.distributors !== null)
        this.formTambah.distributor = `${data.distributors.name} (${data.distributors.owner})`;
      if (data.retailers !== null)
        this.formTambah.r1 = `${data.retailers.name} (${data.retailers.owner})`;
      this.formTambah.kegiatan = this.convertKegiatan(data.kegiatan);
      this.formTambah.tanggal = data.tanggal;
      this.formTambah.lokasi = data.lokasi;
      this.formTambah.crop = data.tanamans.crop;
      this.formTambah.produk = data.produks.brand;

      this.$bvModal.show("modal-plan");
    },
    closeModal() {
      this.$bvModal.hide("modal-plan");
    },
    resetForm() {
      this.formTambah.territory = "";
      this.formTambah.sp = "";
      this.formTambah.distributor = "";
      this.formTambah.r1 = "";
      this.formTambah.kegiatan = "";
      this.formTambah.tanggal = "";
      this.formTambah.lokasi = "";
      this.formTambah.crop = "";
      this.formTambah.produk = "";
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.post(
        process.env.VUE_APP_URL_GRAPHQL + "/monthly-plan/excel",
        {
          dateType: this.handleCustomDateExport(this.filterDateType),
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          user:
            this.filterUser.length === 0 ? this.dataUserId : this.filterUser,
          region: [],
          kegiatan: this.convertKegMP(this.filterKegiatan)
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `monthlyplan-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    },
    convertKegiatan(data) {
      let Value;
      switch (data) {
        case "DP":
          Value = "Demo Plot";
          break;
        case "FFD":
          Value = "FFD";
          break;
        case "SMF":
          Value = "Small FM";
          break;
        case "SAF":
          Value = "Safari FM";
          break;
        case "ODP":
          Value = "ODP";
          break;
        case "BF":
          Value = "Big FM";
          break;
        case "KF":
          Value = "Key FM";
          break;
        case "SE":
          Value = "Sponsorship Event";
          break;
        case "R1M":
          Value = "R1 Meeting";
          break;
        case "R2M":
          Value = "R2 Meeting";
          break;
        default:
          Value = "Wrong Activity";
          break;
      }
      return Value;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
  }
};
</script>
<style></style>
