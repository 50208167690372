<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("report.tssb") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3">
            <h6 class="ssp-24 ml mb-1">{{ $t("report.tss") }}</h6>
          </div>
          <div class="mx-3 mt-3">
            <b-tabs content-class="mt-3" class="tab-custom">
              <b-tab
                :title="$t('report.liquidation')"
                title-link-class="sp-report-tabs"
                active
              >
                <TabelLikuidasi />
              </b-tab>
              <b-tab
                :title="$t('report.daily')"
                title-link-class="sp-report-tabs"
              >
                <TabelDailyReport />
              </b-tab>
              <b-tab
                :title="$t('report.monthly')"
                title-link-class="sp-report-tabs"
              >
                <TabelMonthlyPlan />
              </b-tab>
              <b-tab
                :title="$t('report.target')"
                title-link-class="sp-report-tabs"
              >
                <TabelTarget />
              </b-tab>
            </b-tabs>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import TabelLikuidasi from "./Likuidasi.vue";
import TabelDailyReport from "./DailyReport.vue";
import TabelMonthlyPlan from "./MonthlyPlan.vue";
import TabelTarget from "./SPTarget.vue";

export default {
  components: {
    Card,
    TabelLikuidasi,
    TabelDailyReport,
    TabelMonthlyPlan,
    TabelTarget
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style>
.sp-report-tabs.active {
  color: #000 !important;
  background-color: #f3f3f3 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 2px solid #fd8833 !important;
}

.sp-report-tabs {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #273b4a;
}

.tab-custom div .nav-tabs {
  background-color: #f3f3f3;
  border-radius: 8px;
}
</style>
